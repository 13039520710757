import { arrayOf, func, object, oneOfType, shape, string } from 'prop-types'
import Slider from 'react-slick'

import Audio from './Audio'
import { makeArrow, MAX_REGULAR_THUMBS } from './utils'
import { PhotoPropType } from './Photo'
import Thumb from './Thumb'

import './slick-1.6.0.min.css'
import './Photos.scss'

const ThumbSlider = ({ arrowsImageURL, mainSliderRef, sliderRef, slides }) => {
  const PrevArrow = makeArrow({
    alt: 'Photo précédente',
    direction: 'previous',
    icon: arrowsImageURL,
  })
  const NextArrow = makeArrow({
    alt: 'Photo suivante',
    direction: 'next',
    icon: arrowsImageURL,
  })

  return (
    <Slider
      asNavFor={mainSliderRef}
      className='c-glide__thumbnail-wrapper c-glide__thumbnail_wrapper--subslider u-hidden@us'
      cssEase='cubic-bezier(0.165, 0.840, 0.440, 1.000)'
      focusOnSelect
      infinite={false}
      nextArrow={<NextArrow />}
      prevArrow={<PrevArrow />}
      lazyLoad='progressive'
      ref={sliderRef}
      slidesToShow={MAX_REGULAR_THUMBS}
      slidesToScroll={MAX_REGULAR_THUMBS}
      speed={400}
    >
      {slides.map(({ alt, audio, thumb }, index) =>
        audio ? (
          <Audio key={index} url={audio} thumb />
        ) : (
          <Thumb key={index} alt={alt} {...thumb} />
        )
      )}
    </Slider>
  )
}

ThumbSlider.propTypes = {
  arrowsImageURL: string.isRequired,
  // As sub slider and main slider are loaded at the same time, refs are not
  // created on first render. Therefore ref cannot be marked as required.
  // `object` goes for `null`.
  mainSliderRef: oneOfType([func, object]),
  sliderRef: func.isRequired,
  slides: arrayOf(
    shape({
      alt: string,
      audio: string,
      main: PhotoPropType.isRequired,
      thumb: PhotoPropType.isRequired,
    })
  ).isRequired,
}

export default ThumbSlider
