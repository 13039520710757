/**
 * Safety wrapper around the DOM's `Element#matches()` method, as it is
 * unsupported on IE (where we polyfill it).
 *
 * @param {Element} start The element to walk our way upwards from.
 * @param {*} selector The CSS selector to match against.
 * @returns Either the `start` element or one of its ancestors, or `null` if no
 * ancestor matches.
 */
export function closest(start, selector) {
  if ('closest' in start) {
    return start.closest(selector)
  }

  let result = start
  while (result && !result.matches(selector)) {
    result = result.parentNode
  }

  return result
}

/**
 * A custom "event delegation" vanilla JS implementation so we can avoid
 * manually binding tons of event listeners.
 *
 * Example call:
 *
 * ```js
 * on('js-navigation', 'click', '.c-nav__item', toggleFirstLevel)
 * ```
 *
 * @param {String|Element} root A DOM element or CSS selector for targeting the
 * container elements.
 * @param {String} eventType A DOM event name we're listening for inside
 * container elements.
 * @param {String} descendantSelector A CSS selector for targeting descendants
 * inside containers.
 * @param {Function} handler The event handler function we're calling
 * eventually.
 */
export function on(root, eventType, descendantSelector, handler) {
  const elements =
    typeof root === 'string' ? document.querySelectorAll(root) : [root]
  for (const element of elements) {
    element?.addEventListener(eventType, (event) => {
      if (event.target.matches(descendantSelector)) {
        handler(event)
      }
    })
  }
}
