import { adjustLabelForOptionality } from './Option'
import { ProductOptionWithNamePropTypes } from '../../../../../shared/js/prop-types'

const Input = ({
  details,
  disabled,
  id,
  fontClassName,
  label,
  name,
  onChange,
  required,
  values: [value],
  valid,
}) => {
  const inputId = `input-${id}`
  const descriptionId = details ? `description-input-${id}` : null
  const ariaDescribedBy = details ? { 'aria-describedby': descriptionId } : {}
  if (!required) {
    label = adjustLabelForOptionality(label)
  }

  return (
    <div className='u-mb-2'>
      <label
        className='c-label c-label--image'
        htmlFor={inputId}
        {...ariaDescribedBy}
      >
        {label}
      </label>
      <input
        aria-required={required}
        aria-invalid={!valid}
        className={value?.fontPreview && fontClassName}
        defaultValue={value?.text || ''}
        disabled={disabled}
        id={inputId}
        name={name}
        onChange={handleChange}
        type='text'
      />
      {!valid && (
        <p className='c-message c-message--error'>
          Vous devez renseigner un texte
        </p>
      )}
      {details && (
        <p id={descriptionId} className='c-message c-message--help'>
          {details}
        </p>
      )}
    </div>
  )

  function handleChange({ target: { value: text } }) {
    onChange(id, [{ ...value, text, selected: text.length > 0 }])
  }
}

Input.propTypes = ProductOptionWithNamePropTypes

export default Input
