import {
  any,
  arrayOf,
  bool,
  func,
  number,
  oneOf,
  shape,
  string,
} from 'prop-types'

export const ProductOptionValuePropTypes = {
  cents: number.isRequired,
  id: number.isRequired,
  imgURL: string,
  imgKind: string,
  label: string,
  taxCents: number,
  // Is there any font to preview?
  fontClassName: string,
  // ProductOptionPropTypes expected, but we don’t want to create
  // an infinite loop
  options: any,
  // Used when a  boolean or selection value is set
  selected: bool,
  // Used when a textual value is set
  text: string,
}

export const ProductOptionPropTypes = {
  displayRepresentation: oneOf([
    'button',
    'checkbox',
    'input',
    'radio',
    'select',
    'text',
  ]),
  details: string,
  disabled: bool,
  id: number.isRequired,
  kind: oneOf(['boolean', 'literal', 'selection']).isRequired,
  label: string,
  placeholder: string,
  required: bool,
  valid: bool,
  values: arrayOf(shape(ProductOptionValuePropTypes)),
}

export const ProductOptionWithNamePropTypes = {
  ...ProductOptionPropTypes,
  name: string.isRequired,
  onChange: func.isRequired,
  // Field/form validation
  valid: bool.isRequired,
}
