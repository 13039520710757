import { any, shape, string } from 'prop-types'
import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import { useState } from 'react'

export default function StripeForm({
  clientSecret,
  flash,
  returnURL,
  stripePromise,
}) {
  if (flash) {
    return (
      <div className={`c-message c-message--${flash.kind}`}>
        {flash.message}
      </div>
    )
  }

  if (!clientSecret) {
    return
  }

  return (
    <Elements stripe={stripePromise} options={{ clientSecret }}>
      <CheckoutForm returnURL={returnURL} />
    </Elements>
  )
}

StripeForm.propTypes = {
  clientSecret: string,
  flash: shape({
    kind: string,
    message: string,
  }),
  returnURL: string.isRequired,
  stripePromise: any.isRequired,
}

function CheckoutForm({ returnURL }) {
  const stripe = useStripe()
  const elements = useElements()
  const [error, setError] = useState()

  return (
    <>
      {error && <div className={`c-message c-message--error`}>{error}</div>}
      <form onSubmit={handleSubmit}>
        <PaymentElement />
        <button disabled={!stripe}>Payer</button>
      </form>
    </>
  )

  async function handleSubmit(event) {
    setError('')

    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault()

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return
    }

    const result = await stripe.confirmPayment({
      // `Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: returnURL,
      },
    })

    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      setError(result.error.message)
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  }
}

CheckoutForm.propTypes = {
  returnURL: string.isRequired,
}
