import { func } from 'prop-types'

import { VariantsPropTypes } from '../../shared/prop-types'

const Variants = ({
  displayWeight,
  headings,
  onChange,
  selectedId,
  variants,
  guide,
  openGuideDialog,
}) => {
  return (
    <div className='u-mb-3'>
      <label htmlFor='select-family'>Taille</label>
      <select
        id='select-family'
        className='c-select c-select--inline'
        onChange={handleChange}
        value={selectedId}
      >
        {variants.map(({ id, labels: [sizeLabel], quantity }) => (
          <option key={id} role='link' value={id}>
            {sizeLabel}
            {quantityLabel(quantity)}
          </option>
        ))}
      </select>

      {/* Size guide CTA, if applicable */}
      {guide && (
        <a
          aria-haspopup='dialog'
          className='c-link c-link--icon'
          href={guide.url}
          onClick={openGuideDialog}
        >
          📏 {guide.title}
        </a>
      )}
    </div>
  )

  // Set selected variant
  function handleChange({ target: { value } }) {
    const variantId = Number(value)
    const updatedVariants = variants.map((variant) => ({
      ...variant,
      selected: variant.id === variantId,
    }))
    onChange(updatedVariants)
  }

  function quantityLabel(quantity) {
    if (quantity > 2) {
      return ''
    }

    return quantity === 0 ? ' (épuisé)' : ' (dernier exemplaire)'
  }
}

Variants.propTypes = {
  ...VariantsPropTypes,
  onChange: func.isRequired,
}

export default Variants
