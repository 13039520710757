import { string } from 'prop-types'
import { useState } from 'react'

import {
  camelToSnakeCaseObjectKeys,
  snakeToCamelCase,
} from '../../../../../shared/js/formatters'
import DatePicker from '../../shared/DatePicker'
import Dialog from '../../shared/Dialog'
import { put } from '../../../../../shared/js/json-fetch'

const Comment = ({ comment: initialComment = '', url }) => {
  const [comment, setComment] = useState(initialComment)
  // Store last sent values in order to prevent useless server calls
  const [legacyValue, setLegacyValue] = useState(initialComment)
  const [uxState, setUxState] = useState({
    active: false,
    error: null,
    pending: false,
  })
  const { active, error, pending } = uxState

  return (
    <>
      <div className='c-form__block c-form__block--comment'>
        <div className='c-form__event-content'>
          <span className='c-form__event-title'>Commentaire</span>
          <p>Vous souhaitez nous apporter une précision supplémentaire ?</p>

          {comment && (
            <span className='u-block'>
              <small>Commentaire : {comment}</small>
            </span>
          )}
        </div>
        <div className='c-form__event-btn'>
          <button
            className='c-btn c-btn--secondary'
            type='button'
            aria-haspopup='dialog'
            onClick={openDialog}
          >
            Ajouter un commentaire
          </button>
        </div>
      </div>

      <Dialog
        className='right-action-modal'
        onCancel={handleCancel}
        opened={active}
        title='Informations complémentaires'
      >
        <span className='c-contextual-information u-txt-center'>
          Quelles précisions voulez-vous nous fournir ?
        </span>

        {error && (
          <div className={'c-message c-message--error u-mb-2'}>{error}</div>
        )}
        <div className='right-action-modal__separator'>
          <div className='u-mb-2'>
            <label htmlFor='personalized-message'>Dites-nous tout</label>
            <textarea
              name='comment'
              onChange={handleValueChange}
              value={comment}
            />
          </div>
        </div>

        <div className='right-action-modal__btn'>
          <button
            className='c-btn c-btn--fill'
            onClick={handleCancel}
            type='reset'
          >
            Annuler le commentaire
          </button>
          <button
            className='c-btn c-btn--primary'
            disabled={pending || !validateComment()}
            onClick={handleValidate}
          >
            Valider
          </button>
        </div>
      </Dialog>
    </>
  )

  // Cancel values
  async function handleCancel() {
    const newState = {
      error: null,
      active: false,
    }
    // Send empty values to server only when values have changed
    // since last call.
    if (legacyValue !== comment) {
      const { error } = await put(url, {
        comment: '',
      })
      // Print error if any
      if (error) {
        newState.error = error
        newState.active = true
      } else {
        // otherwise store empty values as legacy (last sent)
        setLegacyValue('')
      }
    }
    setComment('')
    setUxState(newState)
  }

  function handleValueChange({ target: { value } }) {
    setComment(value)
  }

  // Send data to server, then update list and select created address
  async function handleValidate(event) {
    event.preventDefault()
    setUxState({ active: true, pending: true, error: '' })

    const { flash: { kind, message } = {} } = await put(url, { comment })
    if (kind === 'error') {
      setUxState({ active: true, pending: false, error: message })
    } else {
      setUxState({
        active: false,
        pending: false,
        error: null,
      })
      setLegacyValue(comment)
    }
  }

  function openDialog(event) {
    event.preventDefault()
    setUxState({ ...uxState, active: true })
  }

  // Form can be submitted when there is at least one field set.
  function validateComment() {
    return Boolean(comment?.trim())
  }
}

Comment.propTypes = {
  comment: string,
  url: string.isRequired,
}

export default Comment
