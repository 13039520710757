// Prevent multiple means selection due to multiple forms presence on paymeny

import { loadStripe } from '@stripe/stripe-js'

import mountReactContainer from '../../../../shared/js/react-mounting'
import { post } from '../../../../shared/js/json-fetch'
import StripeForm from '../../components/purchase-process/means/StripeForm'

// means page
function handlePaymentMeansInputs() {
  const inputs = document.querySelectorAll('input[name="payment-mode"]')

  function uncheckInputs(id) {
    for (const input of inputs) {
      input.checked = input.id === id
    }
  }

  for (const input of inputs) {
    input.addEventListener('click', () => {
      uncheckInputs(input.id)
      // Load Stripe form only when Stripe is selected
      if (input.id === 'js-stripe-input') {
        mountStripeForm()
      }
    })
  }
}

handlePaymentMeansInputs()

async function mountStripeForm() {
  const formId = 'js-stripe-form'
  const publicKey = document
    .getElementById(formId)
    ?.getAttribute('data-public-key')

  // Make sure to call `loadStripe` outside of a component’s render to avoid
  // recreating the `Stripe` object on every render.
  const stripePromise = loadStripe(publicKey)

  // Custom mounting for Stripe form.
  // We must prepare a payment intent when Stripe is selected.
  // Then the form is mounted/displayed.
  const { flash, clientSecret } = await post('/panier/stripe/payment-intent')
  mountReactContainer({
    clientSecret,
    id: formId,
    component: StripeForm,
    flash,
    stripePromise,
  })
}
