import { func } from 'prop-types'
import React, { useState } from 'react'

import { computeOptionsCents } from '../../../../../shared/js/options-utils'
import { formatDate, formatPrice } from '../../../../../shared/js/formatters'
import { LineItemPropTypes } from '../../../shared/prop-types'
import Option, {
  AUTO_SUBMITTING_OPTION_KINDS,
} from '../../product/options/Option'
import OptionsSummary from '../../../../../shared/js/components/OptionsSummary'

// Cart view table row
const LineItem = ({
  disabled,
  htmlPicture,
  id,
  options: initialOptions,
  maxQuantity,
  name,
  quantity,
  reference,
  onDelete,
  onOptionChange,
  onQuantityChange,
  preorderSale,
  unitCents,
  unitTaxCents,
  url,
}) => {
  const [editOptions, setEditOptions] = useState(false)
  const [options, setOptions] = useState(initialOptions)
  const { optionsCents, optionsTaxCents } = computeOptionsCents(options)
  const totalCents = unitCents + unitTaxCents + optionsCents + optionsTaxCents

  const unitPrice = formatPrice(totalCents, { mode: 'net' }) + ' l’unité'
  const quantifiedPrice = formatPrice(totalCents * quantity, {
    mode: 'net',
  })

  return (
    <tr className='_c-cart-summary__tr' data-testid={`line_item-${id}`}>
      <td className='c-cart-summary__thumbnail _c-cart-summary__td'>
        <a
          href={url}
          tabIndex='-1'
          dangerouslySetInnerHTML={{ __html: htmlPicture }}
        ></a>
      </td>
      <td className='c-cart-summary__details _c-cart-summary__td'>
        {/* eslint-disable-next-line react/jsx-no-target-blank */}
        <a className='c-cart-summary__product' href={url} target='_blank'>
          {name}
        </a>
        <small>Référence {reference}</small>
        <div dangerouslySetInnerHTML={{ __html: unitPrice }} />
        <div className='c-cart-summary__engraving'>
          {editOptions ? (
            <>
              {options.map((option) => (
                <React.Fragment key={option.id}>
                  <div className='u-mr-2 u-mb-2'>
                    <Option
                      {...option}
                      onCancel={handleOptionCancel}
                      onChange={handleOptionChange}
                      onSubmit={
                        AUTO_SUBMITTING_OPTION_KINDS.includes(option.kind)
                          ? closeOptionsEditing
                          : undefined
                      }
                      disabled={disabled}
                    />
                  </div>
                </React.Fragment>
              ))}
              {optionsNeedManualValidation() && (
                <div className='u-mr-2 u-mb-2'>
                  <button
                    className='c-btn c-btn--secondary'
                    onClick={(event) => closeOptionsEditing({ event })}
                  >
                    Terminer
                  </button>
                </div>
              )}
            </>
          ) : (
            <OptionsSummary options={options} onEdit={openOptionsEditing} />
          )}
        </div>
        {preorderSale && (
          <div className='c-cart-summary__preorder-sale'>
            L’envoi de ce produit en{' '}
            <a
              href='/page/pre-commandes'
              rel='noopener noreferrer'
              target='_blank'
            >
              pré-commande
            </a>{' '}
            est prévu le{' '}
            <strong>{formatDate(preorderSale.expectShippingOn)}</strong>.
          </div>
        )}
      </td>
      <td className='c-cart-summary__quantity _c-cart-summary__td'>
        {maxQuantity === 1 ? (
          <span>{maxQuantity}</span>
        ) : (
          <>
            <label htmlFor='select-01'>Quantité</label>
            <select
              className='c-select c-select--quantity'
              name={`line_items[${id}][quantity]`}
              onChange={handleQuantityChange}
              value={quantity}
              disabled={disabled}
              data-testid={`line_item-${id}-quantity`}
            >
              {Array.from(Array(maxQuantity), (_, qty) => (
                <option key={qty} value={qty + 1}>
                  {qty + 1}
                </option>
              ))}
            </select>
          </>
        )}
      </td>
      <td className='c-cart-summary__price _c-cart-summary__td'>
        <span
          className='c-price'
          dangerouslySetInnerHTML={{
            __html: quantifiedPrice,
          }}
        />
      </td>
      <td className='c-cart-summary__removal _c-cart-summary__td'>
        {disabled ? (
          <span className='c-tag c-tag--remove'>Supprimer</span>
        ) : (
          <a
            className='c-tag c-tag--remove'
            href=''
            onClick={handleDelete}
            data-testid={`line_item-${id}-removal`}
          >
            Supprimer
          </a>
        )}
      </td>
    </tr>
  )

  function openOptionsEditing(event) {
    event?.preventDefault()
    setEditOptions(true)
  }

  // Updated options are forced on auto-submit to prevent state inconsistency
  // before re-render (options auto-validation is called right after state update).
  function closeOptionsEditing({ event, updatedOptions = options } = {}) {
    event?.preventDefault()
    setEditOptions(false)
    // We were in editOptions mode until then, which means we just completed
    // option tweaking, requiring a notification to the parent (that will
    // likely persist to the server).
    //
    // Do not submit when options and initialOptions are deep equal
    if (JSON.stringify(updatedOptions) !== JSON.stringify(initialOptions)) {
      onOptionChange(id, updatedOptions)
    }
  }

  // Line item removal
  function handleDelete(event) {
    event.preventDefault()
    onDelete(id)
  }

  // Reset option to its initial value
  function handleOptionCancel(optId) {
    const initialOption = initialOptions.find((option) => option.id === optId)
    // Force deselection
    const values = initialOption.values.map((value) => ({
      ...value,
      selected: false,
    }))
    const restoredOptions = options.map((option) =>
      option.id === optId ? { ...initialOption, values } : option
    )
    onOptionChange(id, restoredOptions)
    setOptions(restoredOptions)
  }

  // Dynamically store filled values for expected option.
  // A callback can be passed to manage auto-validation after state is updated.
  function handleOptionChange(optId, values, callbackFn = undefined) {
    const updatedOptions = options.map((option) =>
      option.id === optId ? { ...option, values } : option
    )
    setOptions(updatedOptions)
    callbackFn && callbackFn({ updatedOptions })
  }

  function handleQuantityChange({ target: { value } }) {
    const updatedQuantity = Number(value)
    // Ensure not to trigger callback if value is still the same
    if (quantity !== updatedQuantity) {
      onQuantityChange(id, updatedQuantity)
    }
  }

  // Check if any option needs manual validation on edit mode
  function optionsNeedManualValidation() {
    return options.some(
      ({ kind }) => !AUTO_SUBMITTING_OPTION_KINDS.includes(kind)
    )
  }
}

LineItem.propTypes = {
  ...LineItemPropTypes,
  onDelete: func.isRequired,
  onOptionChange: func.isRequired,
  onQuantityChange: func.isRequired,
}

export default LineItem
