import React from 'react'

import { adjustLabelForOptionality } from './Option'
import { ProductOptionWithNamePropTypes } from '../../../../../shared/js/prop-types'

const Radios = ({
  details,
  disabled,
  id,
  label,
  name,
  onChange,
  onFontChange,
  required,
  values,
  valid,
}) => {
  const selectedValueId = values.find(({ selected }) => selected)?.id
  const descriptionId = details ? `description-input-${id}` : null
  const ariaDescribedBy = details ? { 'aria-describedby': descriptionId } : {}
  if (!required) {
    label = adjustLabelForOptionality(label)
  }

  return (
    <>
      <legend>{label}</legend>
      {values.map(
        ({ id: valueId, imgURL, imgKind, label: valueLabel, cents }) => (
          <React.Fragment key={valueId}>
            <input
              defaultChecked={selectedValueId === valueId}
              disabled={disabled}
              id={`radio-${valueId}`}
              name={name}
              onChange={handleChange}
              type='radio'
              value={valueId}
              {...ariaDescribedBy}
            />
            <label
              className={
                'c-label c-label--image' +
                (imgKind ? ` c-label--image-${imgKind}` : '')
              }
              htmlFor={`radio-${valueId}`}
            >
              {imgURL && (
                <img
                  src={imgURL}
                  className={`c-label__${imgKind}`}
                  alt={`Prévisualisation de ${valueLabel}`}
                />
              )}
              {valueLabel}
            </label>
          </React.Fragment>
        )
      )}

      {!valid && (
        <p className='c-message c-message--error'>
          Vous devez choisir une option
        </p>
      )}
      {details && (
        <p id={descriptionId} className='c-message c-message--help'>
          {details}
        </p>
      )}
    </>
  )

  function handleChange({ target: { value: selectedId } }) {
    let selectedFontClassName = null
    const updatedValues = values.map((value) => {
      const selected = value.id === Number(selectedId)
      if (selected) {
        selectedFontClassName = value.fontClassName
      }
      return { ...value, selected }
    })
    onChange(id, updatedValues)
    onFontChange?.(selectedFontClassName)
  }
}

Radios.propTypes = ProductOptionWithNamePropTypes

export default Radios
