import { func, object, oneOfType } from 'prop-types'
import Slider from 'react-slick'

import Audio from './Audio'
import { makeArrow, SliderCommonPropTypes } from './utils'
import Photo from './Photo'

import './Photos.scss'
import './slick-1.6.0.min.css'

function dot(index) {
  return (
    <button className='c-glide__bullet'>
      <span className='u-visually-hidden'>Photo {index + 1}</span>
    </button>
  )
}

export default function MainSlider({
  arrowsImageURL,
  onChange,
  sliderRef,
  slides,
  subSliderRef,
}) {
  const PrevArrow = makeArrow({
    alt: 'Photo précédente',
    direction: 'previous',
    icon: arrowsImageURL,
  })
  const NextArrow = makeArrow({
    alt: 'Photo suivante',
    direction: 'next',
    icon: arrowsImageURL,
  })

  return (
    <Slider
      afterChange={onChange}
      asNavFor={subSliderRef}
      className='c-glide__track'
      cssEase='cubic-bezier(0.165, 0.840, 0.440, 1.000)'
      customPaging={dot}
      dots
      dotsClass='c-glide__bullet-wrapper u-hidden@fs'
      infinite
      lazyLoad='progressive'
      nextArrow={<NextArrow />}
      prevArrow={<PrevArrow />}
      ref={sliderRef}
      slidesToShow={1}
      slidesToScroll={1}
      speed={400}
    >
      {slides.map(({ alt, audio, main }, index) =>
        audio ? (
          <Audio url={audio} key={index} />
        ) : (
          <Photo key={index} alt={alt} {...main} />
        )
      )}
    </Slider>
  )
}

MainSlider.propTypes = {
  ...SliderCommonPropTypes,
  onChange: func,
  sliderRef: func.isRequired,
  // As sub slider and main slider are loaded at the same time, refs are not
  // created on first render. Therefore ref cannot be marked as required.
  // `object` goes for `null`.
  subSliderRef: oneOfType([func, object]),
}
