import { differenceInCalendarDays } from 'date-fns'

const FORMATTER = new Intl.DateTimeFormat('fr-FR', {
  weekday: 'long',
  day: 'numeric',
  month: 'long',
  year: 'numeric',
})

const SPECIAL_FORMATS = ['Aujourd’hui', 'Demain', 'Après-demain']

export function formatDate(date) {
  if (typeof date === 'string') {
    date = new Date(date)
  }

  const diff = differenceInCalendarDays(date, new Date())
  return SPECIAL_FORMATS[diff] || FORMATTER.format(date)
}
