import { bool, elementType, func, objectOf, oneOf } from 'prop-types'
import { createElement, useState } from 'react'

import Button from './Button'
import Checkbox from './Checkbox'
import {
  computeOptionsValidity,
  computeOptionValidity,
} from '../../../../../shared/js/options-utils'
import Input from './Input'
import OptionSummary from './OptionSummary'
import { ProductOptionPropTypes } from '../../../../../shared/js/prop-types'
import Radios from './Radios'
import Select from './Select'
import TextArea from './TextArea'

// Option kinds that won‘t need manual validation on option value change/update.
export const AUTO_SUBMITTING_OPTION_KINDS = ['boolean', 'selection']

const OPTION_COMPONENTS = {
  button: Button,
  checkbox: Checkbox,
  input: Input,
  radio: Radios,
  select: Select,
  text: TextArea,
}

// Dynamically load component depending on expected display mode.
// Display/Component mapping is done with `optionsComponents`.
const Option = ({
  displayRepresentation,
  onCancel,
  onChange,
  onFontChange,
  optionsComponents = OPTION_COMPONENTS,
  deleteDisabled = false,
  SummaryComponent = OptionSummary,
  ...props
}) => {
  const optionComponent = optionsComponents[displayRepresentation]
  const [optionsValidity, setOptionsValidity] = useState({})
  const [fontClassName, setFontClassName] = useState(null)

  if (optionComponent === undefined) {
    return null
  }

  const { values } = props
  const options = values[0]?.options

  const valid = computeOptionsValidity(options)

  // Group siblings for display purpose
  const groupedOptions = groupSiblingOptions(options)

  const children = groupedOptions?.map((options, index) => {
    return (
      <fieldset key={index} className='right-action-modal__separator'>
        {options.map((option) => (
          <Option
            {...option}
            key={option.id}
            fontClassName={fontClassName}
            name={`${option.displayRepresentation}-${option.id}`}
            onChange={handleChildChange}
            onFontChange={setFontClassName}
            optionsComponents={optionsComponents}
            valid={
              optionsValidity[option.id] === undefined ||
              optionsValidity[option.id]
            }
          />
        ))}
      </fieldset>
    )
  })

  return (
    <>
      <SummaryComponent deleteDisabled onCancel={onCancel} {...props} />
      {createElement(
        optionComponent,
        {
          ...props,
          name: `${displayRepresentation}-${props.id}`,
          onCancel,
          onChange,
          onFontChange,
          // When options exist, mix options validity with current option validity
          valid: options.length ? valid && props.valid : props.valid,
        },
        children
      )}
    </>
  )

  // Manage children updates transfer to `onchange` callback.
  function handleChildChange(optionId, optValues) {
    const updatedOptions = options?.map((option) =>
      option.id !== optionId ? option : { ...option, values: optValues }
    )
    const updatedValues = [{ ...values[0], options: updatedOptions }]
    onChange(props.id, updatedValues)

    // Check if current option is valid after change
    const updatedOption = updatedOptions.find(({ id }) => id === optionId)
    setOptionsValidity({
      ...optionsValidity,
      [optionId]: computeOptionValidity(updatedOption),
    })
  }
}

Option.propTypes = {
  ...ProductOptionPropTypes,
  displayRepresentation: oneOf(Object.keys(OPTION_COMPONENTS)).isRequired,
  // Used by backoffice to prevent option removal.
  deleteDisabled: bool,
  // onCancel is only required for root level options.  We don’t want to manage
  // suboptions removal.
  onCancel: func,
  // Dynamic components
  optionsComponents: objectOf(elementType),
  // FO and BO use a different render for summary
  SummaryComponent: elementType,
}
Option.defaultProps = {
  valid: true,
}

const REGEX_LABEL = /^(.+?)(\s+:)?\s*$/

export function adjustLabelForOptionality(label) {
  const [, coreLabel, suffix] = label.match(REGEX_LABEL)
  return (
    <>
      {coreLabel}
      {suffix}
    </>
  )
}

// When many options with the same display representation follow each other then
// group them (for display purpose).
function groupSiblingOptions(options) {
  const groupedOptions = []
  let previousDisplayRep

  for (const option of options) {
    if (previousDisplayRep === option.displayRepresentation) {
      const lastGroup = groupedOptions.pop()
      groupedOptions.push([...lastGroup, option])
    } else {
      groupedOptions.push([option])
    }
    previousDisplayRep = option.displayRepresentation
  }

  return groupedOptions
}

export default Option
