import { arrayOf, func, number, shape, string } from 'prop-types'
import L from 'leaflet'
import { MapContainer, Marker, Popup, TileLayer, useMap } from 'react-leaflet'

import markerIcon from '../../../../images/marker-icon.svg'
import markerSelectedIcon from '../../../../images/marker-icon-selected.svg'

import 'leaflet/dist/leaflet.css'
// Re-uses images from ~leaflet package
import 'leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.webpack.css'
import 'leaflet/dist/leaflet'
import 'leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility'

const pointerIcon = new L.Icon({
  iconUrl: markerIcon,
  iconRetinaUrl: markerIcon,
  iconSize: [26, 31],
  iconAnchor: [13, 31], // point of the icon which will correspond to marker's location
  popupAnchor: [0, -31], // point from which the popup should open relative to the iconAnchor
})

const selectedPointerIcon = new L.Icon({
  iconUrl: markerSelectedIcon,
  iconRetinaUrl: markerSelectedIcon,
  iconSize: [26, 31],
  iconAnchor: [13, 31], // point of the icon which will correspond to marker's location
  popupAnchor: [0, -31], // point from which the popup should open relative to the iconAnchor
})

const MapViewSetter = ({ bounds, center, zoom }) => {
  const map = useMap()
  if (bounds) {
    // DEV NOTE: remove this when react-leaflet is fixed
    // Ugly hack due to missing function on map
    if (typeof map.setBound !== 'undefined') {
      map.setBounds(bounds)
    } else {
      map.setView(bounds[0], 13)
    }
  } else {
    map.setView(center, zoom)
  }

  return null
}

const PickupPointsMap = ({ markers, selectedCode, onSelect }) => {
  return (
    <MapContainer className='c-relay-point__map' scrollWheelZoom={false}>
      <MapViewSetter {...positionOrBounds()} />
      <TileLayer
        attribution='© contributeurs <a href="http://osm.org/copyright">OpenStreetMap</a>'
        url='https://{s}.tile.osm.org/{z}/{x}/{y}.png'
      />
      {markers.map(({ code, countryCode, name, lat, lng }) => (
        <Marker
          icon={code === selectedCode ? selectedPointerIcon : pointerIcon}
          position={[lat, lng]}
          key={name}
          eventHandlers={{
            click: () => onSelect && onSelect({ code, countryCode }),
          }}
          zIndexOffset={selectedCode === code ? 1 : 0}
        >
          <Popup>{name}</Popup>
        </Marker>
      ))}
    </MapContainer>
  )

  function positionOrBounds() {
    // Center selected marker if any
    if (selectedCode) {
      const marker = markers.find(({ code }) => code === selectedCode)
      return { center: [marker.lat, marker.lng], zoom: 16 }
    }

    const lats = markers.map(({ lat }) => lat)
    const lngs = markers.map(({ lng }) => lng)
    // Select outer bounds (max lat/lng and min lat/lng)
    const bounds = [
      [Math.min(...lats), Math.min(...lngs)],
      [Math.max(...lats), Math.max(...lngs)],
    ]
    // Then calculate padding to enlarge display
    const hpad = (bounds[1][1] - bounds[0][1]) * 0.02
    const vpad = (bounds[1][0] - bounds[0][0]) * 0.02
    // And affect padding to calculated bounds
    bounds[0][1] -= hpad
    bounds[1][1] += hpad
    bounds[0][0] -= vpad
    bounds[1][0] += vpad
    return { bounds }
  }
}

PickupPointsMap.propTypes = {
  markers: arrayOf(
    shape({
      code: string.isRequired,
      countryCode: string.isRequired,
      name: string.isRequired,
      lat: number.isRequired,
      lng: number.isRequired,
    })
  ),
  onSelect: func,
  selectedCode: string,
}

export default PickupPointsMap
