import { adjustLabelForOptionality } from './Option'
import { ProductOptionWithNamePropTypes } from '../../../../../shared/js/prop-types'

const Select = ({
  details,
  disabled,
  id,
  label,
  name,
  onChange,
  onSubmit,
  required,
  values,
  valid,
}) => {
  const selectedValue = values.find(({ selected }) => selected)?.id
  const descriptionId = details ? `description-input-${id}` : null
  const ariaDescribedBy = details ? { 'aria-describedby': descriptionId } : {}
  if (!required) {
    label = adjustLabelForOptionality(label)
  }

  return (
    <>
      <legend>{label}</legend>
      <select
        aria-invalid={!valid}
        className='c-select'
        defaultValue={selectedValue}
        disabled={disabled}
        name={name}
        onChange={handleChange}
        {...ariaDescribedBy}
      >
        {values.map(({ cents, id: valueId, label: valueLabel }) => (
          <option key={valueId} value={valueId}>
            {valueLabel}
          </option>
        ))}
      </select>
      {!valid && (
        <p className='c-message c-message--error'>
          Vous devez choisir une valeur
        </p>
      )}
      {details && (
        <p id={descriptionId} className='c-message c-message--help'>
          {details}
        </p>
      )}
    </>
  )

  function handleChange({ target: { value: selectedId } }) {
    const updatedValues = values.map((value) => {
      const selected = value.id === Number(selectedId)
      return { ...value, selected }
    })
    onChange(id, updatedValues, onSubmit)
  }
}

Select.propTypes = ProductOptionWithNamePropTypes

export default Select
